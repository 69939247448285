import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/Users/admin/IdeaProjects/credit-ms-web/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import moment from 'moment';

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
export function getFileType(filename) {
  var startIndex = filename.lastIndexOf('.');
  if (startIndex !== -1) {
    return filename.substring(startIndex + 1, filename.length).toLowerCase();
  } else {
    return '';
  }
}
export function randomNum(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [];
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (var i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (var _i = 0; _i < 36; _i++) {
      if (!uuid[_i]) {
        r = 0 | Math.random() * 16;
        uuid[_i] = chars[_i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  return uuid.join('') + new Date().getTime();
}

// 把地址栏参数变为接送数组
export function getUrlMethod() {
  var url = decodeURIComponent(window.location.href);
  var jsonList = {};
  if (url.indexOf('?') > -1) {
    var str = url.slice(url.indexOf('?') + 1);
    var strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      jsonList[strs[i].split('=')[0]] = strs[i].split('=')[1]; // 如果出现乱码的话，可以用decodeURI()进行解码
    }
  }
  return jsonList;
}
/** 浮点数相加*/
export function accAdd(num1, num2) {
  // eslint-disable-next-line one-var
  var r1 = 0,
    r2 = 0;
  try {
    r1 = (num1.toString().split('.')[1] || []).length;
  } catch (e) {
    return num1 + num2;
  }
  try {
    r2 = (num2.toString().split('.')[1] || []).length;
  } catch (e) {
    return num1 + num2;
  }
  var m = Math.pow(10, Math.max(r1, r2));
  return Math.round(accMul(num1, m) + accMul(num2, m)) / m;
}
export function accSub(num1, num2) {
  var r1, r2;
  try {
    r1 = (num1.toString().split('.')[1] || []).length;
  } catch (e) {
    return num1 - num2;
  }
  try {
    r2 = (num2.toString().split('.')[1] || []).length;
  } catch (e) {
    return num1 - num2;
  }
  var m = Math.pow(10, Math.max(r1, r2));
  return Math.round(accMul(num1, m) - accMul(num2, m)) / m;
}
export function accMul(num1, num2) {
  num1 = num1 + '';
  num2 = num2 + '';
  var m = 0;
  var s1 = num1.toString();
  var s2 = num2.toString();
  try {
    m += (s1.split('.')[1] || []).length;
  } catch (e) {
    return num1 * num2;
  }
  try {
    m += (s2.split('.')[1] || []).length;
  } catch (e) {
    return num1 * num2;
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
}
/**
 * 两个浮点数相除
 * @author yxk
 * @param num1
 * @param num2
 * @return {number}
 */
export function accDiv(num1, num2) {
  var m = 0;
  var s1 = num1.toString();
  var s2 = num2.toString();
  try {
    m += (s1.split('.')[1] || []).length;
  } catch (e) {
    return num1 / num2;
  }
  try {
    m += (s2.split('.')[1] || []).length;
  } catch (e) {
    return num1 / num2;
  }
  var n1 = accMul(num1, Math.pow(10, m));
  var n2 = accMul(num2, Math.pow(10, m));
  return Number(n1) / Number(n2);
}
export function copyText(text) {
  var elInput = document.createElement('input');
  elInput.value = text;
  document.body.appendChild(elInput);
  // 选择对象
  elInput.select();
  this.$message({
    message: 'Copy succeeded',
    type: 'success'
  });
  document.execCommand("Copy");
  elInput.remove();
}

// 获取传入日期的的星期数
export function getWeek(date) {
  var timeVal = moment(date + '').format('YYYYMMDD');
  var secondTime = moment(timeVal).format('YYYY-MM-DD');
  var day = new Date(secondTime).getDay();
  switch (day) {
    case 1:
      return "Mon.";
    case 2:
      return "Tues.";
    case 3:
      return "Wed.";
    case 4:
      return "Thur.";
    case 5:
      return "Fri.";
    case 6:
      return "Sat.";
    case 0:
      return "Sun.";
  }
}

// 跳转合同界面
export function goContractPage(branchCd, rpromissoryesId) {
  var route = this.$router.resolve({
    path: '/finance/intecon/promissory',
    query: {
      branchCd: branchCd,
      rpromissoryesId: rpromissoryesId
    }
  });
  window.open(route.href, '_blank');
}
// 翻译字典值
export function translateDict(value, list) {
  if (list && list.length > 0) {
    var item = list.find(function (item) {
      return item.code === value + '';
    });
    return item ? item.name : '';
  } else {
    return '';
  }
}
// 《计算借款的 店铺数量 和提示信息》
export function getLoanBranchCount(obj) {
  if (obj.loanBranchCount && Number(obj.loanBranchCount) > 2) {
    return 'Borrowing from more than 2 shops';
  } else {
    return 'false';
  }
}

/**
 * 入参是inst 对象
 * @param obj
 * @returns {{msg: string, state: boolean}}
 */
export function checkInstActionDate(obj) {
  var state = false;
  var msg = "";
  if (obj.isCollectionDayAbnormal && obj.isCollectionDayAbnormal === 1) {
    state = true;
    msg = msg + ";" + "CollectionDay anomaly";
  }
  if (obj.isTrackingTimeAbnormal && obj.isTrackingTimeAbnormal === 1) {
    state = true;
    msg = msg + ";" + "The deduction date is less than 3 days";
  }
  if (obj.isActionDateAbnormal && obj.isActionDateAbnormal === 1) {
    state = true;
    msg = msg + ";" + "From the signing of the contract to the deduction of more than 35 days";
  }
  if (obj.instDtCompareNum != null && obj.instDtComparePassNum != null && obj.instDtCompareNum !== obj.instDtComparePassNum) {
    state = true;
    msg = msg + ";" + "Not including all successful deduction dates";
  }
  return {
    state: state,
    msg: msg
  };
}
export function checkProInstActionDate(obj) {
  var state = false;
  var msg = "";
  if (obj.isCollectionDayAbnormal && obj.isCollectionDayAbnormal === 1) {
    state = true;
    msg = msg + ";" + "Inst InstDt anomaly";
  }
  return {
    state: state,
    msg: msg
  };
}

// 元转分
export function yuanToFen(amount) {
  return Number(accMul(amount ? amount : 0, 100)).toFixed(0);
}
// 分转元
export function fenToYuan(amount) {
  return Number(accDiv(amount ? amount : 0, 100)).toFixed(2);
}
// 百分比率
export function percent(amount) {
  return Number(accMul(amount ? amount : 0, 100)).toFixed(2) + "%";
}
// 保留位数
export function toFixedNum(num, n) {
  return Number(num).toFixed(n);
}
export function
/**
*  小数转百分比
* @param num
* @returns {*|string|string}
*/
numToPercent(num) {
  if (num) {
    return (num * 100).toFixed(2) + "%";
  } else {
    return "-";
  }
}

/**
 * instStatus 为 COMPLETE 或者 COMPLETED 时返回 1
 * 否则返回  0
 */
export function instStatusIsComplete(instStatus) {
  return instStatus === 'COMPLETE' || instStatus === 'COMPLETED' ? 1 : 0;
}
export function calculatePercentage(numerator, denominator) {
  if (numerator === null || denominator === null) {
    return 0;
  }
  if (denominator === 0) {
    return 0;
  }
  return Number(numerator / denominator).toFixed(8);
}